<template>
  <div class="pa-0">
    <v-card
        class="mx-auto"
        outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4 text-center" style="border-bottom: 2px solid dodgerblue;">
            Your Result
          </div>
          <v-list-item-title v-if="exam_report" class="subtitle-1 text-center green--text mb-1">
            Obtained Marks: {{ exam_report.obtained_marks }}
          </v-list-item-title>
          <v-list-item-title v-if="exam_report" class="subtitle-1 text-center green--text mb-1">
            Your Position: {{ exam_report.position }} out of {{ exam_report.total_students }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-center">
            <v-btn class="text-center" text max-width="200px" color="primary" v-if="showResult"
                   @click="$router.push({name:'WebExamReport',params:{examId: exam_id}})">See ranking
            </v-btn>
          </v-list-item-subtitle>
          <div v-if="!isAnswerAvailable" class="overline mb-4 text-center" style="border-bottom: 2px solid dodgerblue;">
            Answer will be available {{ answerAvailableAt }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ['exam_report', 'isAnswerAvailable', 'answerAvailableAt', 'exam_id', 'showResult'],
  methods: {
    retry() {
      this.$emit('retry');
    },
    practice() {
      {
        this.$emit('practice')
      }
    }
  }
}
</script>