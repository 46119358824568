<template>
  <v-card rounded class="my-1">
    <v-card-text class="pb-0">
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <div class="subtitle-1 font-weight-bold mr-3">{{ index + 1 }}.</div>
          <div class="subtitle-1" v-html="mcq.question" v-katex:auto></div>
        </div>
        <div class="d-flex">
          <v-btn icon v-if="isWrongAnswer">
            <v-icon color="red">
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn icon v-if="isCorrectAnswer">
            <v-icon color="green">
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn icon v-if="!favorite && mode==='result'" @click="toggleFavorite">
            <v-icon>mdi-heart-outline</v-icon>
          </v-btn>
          <v-btn v-if="favorite && mode==='result'" icon @click="toggleFavorite">
            <v-icon color="red">mdi-heart</v-icon>
          </v-btn>
          <v-btn icon @click="report">
            <v-icon :color="reported ? 'red':'auto'">mdi-flag-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <v-img
          v-if="mcq.question_photo"
          style="max-width: 400px"
          :src="mcq.question_photo"
          class="mb-2"
      ></v-img>
      <v-radio-group
          dense
          :readonly="shouldBeDisabled"
          class="mt-1"
          column
          v-model="user_answer"
      >
        <v-radio
            off-icon="mdi-alpha-a-circle-outline"
            on-icon="mdi-alpha-a-circle-outline"
            :color="color('a')"
            value="a"
        >
          <template v-slot:label>
            <div :style="{'color':labelColor('a')}"
                 v-html="mcq.a" v-katex:auto>
            </div>
          </template>
        </v-radio>
        <v-radio
            off-icon="mdi-alpha-b-circle-outline"
            on-icon="mdi-alpha-b-circle-outline"
            :color="color('b')"
            value="b"
        >
          <template v-slot:label>
            <div :style="{'color':labelColor('b')}"
                 v-html="mcq.b" v-katex:auto
            >
            </div>
          </template>
        </v-radio>
        <v-radio
            off-icon="mdi-alpha-c-circle-outline"
            on-icon="mdi-alpha-c-circle-outline"
            :color="color('c')"
            value="c"
        >
          <template v-slot:label>
            <div :style="{'color':labelColor('c')}"
                 v-html="mcq.c" v-katex:auto
            >
            </div>
          </template>
        </v-radio>
        <v-radio
            off-icon="mdi-alpha-d-circle-outline"
            on-icon="mdi-alpha-d-circle-outline"
            :color="color('d')"
            value="d"
        >
          <template v-slot:label>
            <div :style="{'color':labelColor('d')}"
                 v-html="mcq.d" v-katex:auto
            ></div>
          </template>
        </v-radio>
        <v-radio
            off-icon="mdi-alpha-e-circle-outline"
            on-icon="mdi-alpha-e-circle-outline"
            v-if="mcq.e"
            :color="color('e')"
            value="e"
        >
          <template v-slot:label>
            <div :style="{'color':labelColor('e')}"
                 v-html="mcq.e" v-katex:auto
            ></div>
          </template>
        </v-radio>
      </v-radio-group>
      <v-divider v-if="viewMode && (mcq.answer_description || mcq.answer_photo)" class="mb-2"></v-divider>
      <div v-if="viewMode && mcq.answer_description" v-html="mcq.answer_description" v-katex:auto></div>
      <v-img
          v-if="viewMode && mcq.answer_photo"
          style="max-width: 400px"
          :src="mcq.answer_photo"
          class="mb-2"
      ></v-img>
    </v-card-text>
  </v-card>
</template>
<script>

import axios from "axios";

export default {
  props: ['mcq', 'mode', 'index', 'isAnswerAvailable'],
  data() {
    return {
      answer: this.mcq.answer,
      user_answer: this.mcq.exam_answers.length ? this.mcq.exam_answers[0].user_answer : '',
      favorite: this.mcq.favorite,
      reported: false,
    }
  },
  computed: {
    shouldBeDisabled() {
      return this.mode === 'result' || !!this.user_answer
    },
    viewMode() {
      return this.mode === 'result' && this.isAnswerAvailable
    },
    isSelectedA() {
      return this.user_answer === 'a'
    },
    isWrongA() {
      return this.isSelectedA && (this.mcq[this.user_answer] !== this.mcq.answer) && this.viewMode;
    },
    isSelectedB() {
      return this.user_answer === 'b';
    },
    isWrongB() {
      return this.isSelectedB && (this.mcq[this.user_answer] !== this.mcq.answer) && this.viewMode;
    },
    isSelectedC() {
      return this.user_answer === 'c';
    },
    isWrongC() {
      return this.isSelectedC && (this.mcq[this.user_answer] !== this.mcq.answer) && this.viewMode;
    },
    isSelectedD() {
      return this.user_answer === 'd';
    },
    isWrongD() {
      return this.isSelectedD && (this.mcq[this.user_answer] !== this.mcq.answer) && this.viewMode;
    },
    isSelectedE() {
      return this.user_answer === 'e';
    },
    isWrongE() {
      return this.isSelectedE && (this.mcq[this.user_answer] !== this.mcq.answer) && this.viewMode;
    },
    isCorrectAnswer() {
      return (this.mcq[this.user_answer] === this.mcq.answer) && this.viewMode;
    },
    isWrongAnswer() {
      return (this.mcq[this.user_answer] !== this.answer) && this.viewMode && !!this.user_answer;
    },
    isCorrectA() {
      return (this.mcq.a === this.answer) && this.viewMode && !this.isWrongA;
    },
    isCorrectB() {
      return (this.mcq.b === this.answer) && this.viewMode && !this.isWrongB;
    },
    isCorrectC() {
      return (this.mcq.c === this.answer) && this.viewMode && !this.isWrongC;
    },
    isCorrectD() {
      return (this.mcq.d === this.answer) && this.viewMode && !this.isWrongD;
    },
    isCorrectE() {
      return (this.mcq.e === this.answer) && this.viewMode && !this.isWrongE;
    },
    form() {
      let formData = new FormData()
      formData.append('type', 'answer')
      formData.append('mcq_id', this.mcq.id)
      formData.append('user_answer', this.user_answer)
      formData.append('exam_id', this.mcq.exam_id)
      formData.append('answer', this.mcq.answer)
      return formData
    }
  },
  methods: {
    color(option) {
      if (this.mode === 'group_exam' || this.mode === 'exam') {
        return 'info'
      } else {
        if (option === 'a') {
          if (this.isCorrectA) {
            return 'green'
          }
          if (this.isWrongA) {
            return 'red'
          }
        } else if (option === 'b') {
          if (this.isCorrectB) {
            return 'green'
          }
          if (this.isWrongB) {
            return 'red'
          }
        } else if (option === 'c') {
          if (this.isCorrectC) {
            return 'green'
          }
          if (this.isWrongC) {
            return 'red'
          }
        } else if (option === 'd') {
          if (this.isCorrectD) {
            return 'green'
          }
          if (this.isWrongD) {
            return 'red'
          }
        } else if (option === 'e') {
          if (this.isCorrectE) {
            return 'green'
          }
          if (this.isWrongE) {
            return 'red'
          }
        }
      }
    },
    labelColor(option) {
      if (this.mode === 'group_exam' || this.mode === 'exam') {
        return '#000'
      } else {
        if (option === 'a') {
          if (this.isCorrectA) {
            return '#1ed424'
          }
          if (this.isWrongA) {
            return 'red'
          }
        } else if (option === 'b') {
          if (this.isCorrectB) {
            return '#1ed424'
          }
          if (this.isWrongB) {
            return 'red'
          }
        } else if (option === 'c') {
          if (this.isCorrectC) {
            return '#1ed424'
          }
          if (this.isWrongC) {
            return 'red'
          }
        } else if (option === 'd') {
          if (this.isCorrectD) {
            return '#1ed424'
          }
          if (this.isWrongD) {
            return 'red'
          }
        } else if (option === 'e') {
          if (this.isCorrectE) {
            return '#1ed424'
          }
          if (this.isWrongE) {
            return 'red'
          }
        }
      }
    },
    toggleFavorite() {
      let formData = new FormData()
      formData.append('type', 'favorite')
      formData.append('mcq_id', this.mcq.id)
      formData.append('exam_id', this.mcq.exam_id)
      const url = 'mcqs'
      axios.post(url, formData).then(() => {
        this.favorite = !this.favorite
      })
    },
    report() {
      let formData = new FormData()
      formData.append('type', 'report')
      formData.append('mcq_id', this.mcq.id)
      formData.append('exam_id', this.mcq.exam_id)
      const url = 'mcqs'
      axios.post(url, formData).then(() => {
        this.reported = true
      })
    },
  },
  watch: {
    mode(newVal, olvVal) {
      if ((newVal === 'exam' && olvVal === 'answer') || (newVal === 'exam' && olvVal === 'failed')) {
        this.user_answer = '';
      }
    },
    user_answer() {
      this.$emit('input', this.user_answer)
    }
  }
}
</script>
<style scoped lang="scss">
.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 1) !important;
}

.theme--light.v-input--is-disabled {
  color: rgba(0, 0, 0, 1) !important;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 1) !important;
}

.theme--light.v-radio--is-disabled label {
  color: rgba(0, 0, 0, 1) !important;
}

.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  //margin-bottom: 2px !important;
}

.v-application .accent {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>