<template>
  <v-sheet>
    <div class="d-flex justify-center align-center" style="min-height: 80vh">
      <v-progress-circular
          v-if="!exam"
          :size="70"
          :width="7"
          color="purple"
          indeterminate
      ></v-progress-circular>
    </div>
    <take-web-exam @submitted="initialize"
                   v-if="exam && exam.mode === 'exam'"
                   :exam="exam"
                   :key="exam.id"
    >
    </take-web-exam>
    <web-group-exam @submitted="initialize"
                    v-if="exam && exam.mode === 'group_exam'"
                    :exam="exam"
                    :key="exam.id"
    >
    </web-group-exam>
    <web-practice-exam
        class="ml-lg-10"
        v-if="exam && exam.mode === 'practice'"
        :exam="exam"
        :key="exam.id"
    >
    </web-practice-exam>
  </v-sheet>
</template>
<script>
import axios from "axios";
import TakeWebExam from "@/components/user/exam/TakeWebExam";
import WebGroupExam from "@/components/user/exam/WebGroupExam";
import WebPracticeExam from "@/components/user/exam/WebPracticeExam";

export default {
  props: ['initial_exam'],
  components: {WebPracticeExam, WebGroupExam, TakeWebExam},
  data() {
    return {
      exam: '',
    }
  },
  computed: {},
  methods: {
    initialize() {
      const url = 'exams/' + this.initial_exam.id
      axios.get(url).then((response) => {
        this.exam = response.data
      })
    }
  },
  mounted() {
    this.initialize()
  }
}
</script>